import React from "react";
import "components/FontawsomeIcons";
import TreeList from './pages/TreeList/index.js';

function App() {
    return (
        <div>

          <TreeList />

        </div>
    );
}

export default App;
