import React from "react";
import Tree from "components/Tree";

const treeData = [
  {
    label: "Breast Cancer",
    children: [

  {
    key: "0",
    label: "Germline",
    children: [
      {
        key: "0-0",
        label: "BRCA1/2 Testing",
        children: [
          {
            key: "0-0-0",
            label: "(If it's positive) PARP Inhibitors",
          },
          {
            key: "0-0-1",
            label: "(If it's negative) TP53 Testing",
            children: [
              {
                key: "0-0-1-0",
                label: "(If it's positive) Radiotherapy",
              },
              {
                key: "0-0-1-1",
                label: "(If it's negative) PTEN Testing",
                children: [
                  {
                    key: "0-0-1-1-0",
                    label: "(If it's positive) PI3K Inhibitors",
                  },
                  {
                    key: "0-0-1-1-1",
                    label: "(If it's negative) STK11 Testing",
                    children: [
                      {
                        key: "0-0-1-1-1-0",
                        label: "(If it's positive) PI3K/AKT/mTOR Inhibitors",
                      },
                      {
                        key: "0-0-1-1-1-1",
                        label: "(If it's negative) Chemoterapy + Surgery",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "1",
    label: "Somatic",
    children: [
      {
        key: "1-0",
        label: "Breast Carcinoma",
        children: [
          {
            key: "1-0-0",
            label: "HR (HER/PR) and HER2 Testing",
            children: [
              {
                key: "1-0-0-0",
                label: "Triple Positive",
                children: [
                  {
                    key: "1-0-0-0-0",
                    label: "Endocrine Therapy + HER2 Inhibitors",
                  },
                ],
              },
              {
                key: "1-0-0-1",
                label: "HR(-) HER2(+)",
                children: [
                  {
                    key: "1-0-0-1-0",
                    label: "Chemoterapy + HER2 Inhibitors",
                  },
                ],
                },
              {
                key: "1-0-0-2",
                label: "HR(+) HER2(-)",
                children: [
                  {
                    key: "1-0-0-2-0",
                    label: "(1.Line) Endocrine Therapy and CDK 4/6 Inhibitors",
                  },
                  {
                    key: "1-0-0-2-1",
                    label: "(2.Line) mTOR Testing",
                    children: [
                      {
                        key: "1-0-0-2-1-0",
                        label: "(If it's positive) mTOR Inhibitors + Endocrine Therapy",
                      },
                      {
                        key: "1-0-0-2-1-1",
                        label: "(If it's negative) PIK3CA Testing",
                        children: [
                          {
                            key: "1-0-0-2-1-1-0",
                            label: "(If it's positive) PI3K Inhibitors + Endocrine Therapy",
                          },
                          {
                            key: "1-0-0-2-1-1-1",
                            label: "(If it's negative) Endocrine Therapy and CDK 4/6 Inhibitors",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: "1-0-0-2-2",
                    label: "(3.Line) BRCA1/2 Testing",
                    children: [
                      {
                        key: "1-0-0-2-2-0",
                        label: "(If it's positive) PARP Inhibitors + Endocrine Therapy",
                      },
                      {
                        key: "1-0-0-2-2-1",
                        label: "(If it's negative) Endocrine Therapy and CDK 4/6 Inhibitors",
                      },
                    ],
                    },
                  ], //1-0-0-2'nin diki
                }, //1-0-0-2'nin süslüsü
              {
                key: "1-0-0-3",
                label: "Triple Negative",
                children: [
                  {
                    key: "1-0-0-3-0",
                    label: "(1.Line) BRCA Testing",
                    children: [
                      {
                        key: "1-0-0-3-0-0",
                        label: "(If it's positive) PARP Inhibitors",
                      },
                      {
                        key: "1-0-0-3-0-1",
                        label: "(If it's negative) Chemoterapy",
                      },
                    ],
                  },
                  {
                    key: "1-0-0-3-1",
                    label: "(2.Line) PD-L1 Testing",
                    children: [
                      {
                        key: "1-0-0-3-1-0",
                        label: "(If it's positive) Immune Checkpoint Inhibitors",
                      },
                      {
                        key: "1-0-0-3-1-1",
                        label: "(If it's negative) Chemoterapy",
                      },
                    ],
                  },
                ], //1-0-0-3'ün dik
              }, //1-0-0-3'ün süslüsü
            ],
          },
      ],
    },
      {
        key: "1-1",
        label: "Inflamatory Breast Cancer",
        children: [
          {
            key: "1-1-0",
            label: "HER2 Testing",
            children: [
              {
                key: "1-1-0-0",
                label: "(If it's positive) HER2 Inhibitors + Chemoterapy",
              },
              {
                key: "1-1-0-1",
                label: "(If it's negative) NTRK Fusion Testing",
                children: [
                  {
                    key: "1-1-0-1-0",
                    label: "(If it's positive) TRK Inhibitors",
                  },
                  {
                    key: "1-1-0-1-1",
                    label: "(If it's negative) MSI Testing",
                    children: [
                      {
                        key: "1-1-0-1-1-0",
                        label: "(If it's positive) Chemoterapy + Immune Checkpoint Inhibitors",
                      },
                      {
                        key: "1-1-0-1-1-1",
                        label: "(If it's negative) Chemoterapy + Surgery",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        key: "1-2",
        label: "Metaplastic Breast Cancer",
        children: [
          {
            key: "1-2-0",
            label: "HER2 Testing",
            children: [
              {
                key: "1-2-0-0",
                label: "(If it's positive) HER2 Inhibitors + Chemoterapy",
              },
              {
                key: "1-2-0-1",
                label: "(If it's negative) NTRK Fusion Testing",
                children: [
                  {
                    key: "1-2-0-1-0",
                    label: "(If it's positive) TRK Inhibitors",
                  },
                  {
                    key: "1-2-0-1-1",
                    label: "(If it's negative) MSI Testing",
                    children: [
                      {
                        key: "1-2-0-1-1-0",
                        label: "(If it's positive) Chemoterapy + Immune Checkpoint Inhibitors",
                      },
                      {
                        key: "1-2-0-1-1-1",
                        label: "(If it's negative) Chemoterapy + Surgery",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        key: "1-3",
        label: "Phyllodes Tumor of the Breast",
        children: [
          {
            key: "1-3-0",
            label: "Surgery",
          },
        ],
      },
    ],
  },
 ], //1'in diki
}, //1'in kuyruğu
]
;

const TreeList = () => {
  return (
    <>

      <div className="row">
        <div className="col text-center">
          <h2>Tree Visualization for Breast Cancer</h2>
          <p className="mt-3">
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col-lg-8 text-left text-dark">
                <Tree data={treeData} />
              </div>
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default TreeList;
